import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import zhCN from "date-fns/locale/zh-CN";

export default function DateTimeSelect({
  fullWidth,
  variant,
  label,
  value,
  error,
  margin,
  helperText,
  onChange,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={zhCN}>
      <DateTimePicker
        ampm={false}
        label={label}
        value={value ? new Date(value) : null}
        slotProps={{
          textField: { variant, margin, fullWidth, error, helperText },
        }}
        onChange={onChange}
      />
    </LocalizationProvider>
  );
}
