import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";

export default function ChangeUserStateDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  const submitEdition = () => {
    const putObj = {
      putUserEntList: [{ id: user.id, state: user.state === 1 ? 0 : 1 }],
    };
    _axios.put("/rbac/user", putObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar(
          user.state === 1 ? "禁用账户成功！" : "启用账户成功！",
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(
          user.state === 1 ? "禁用账户失败！" : "启用账户失败！",
          { variant: "error" }
        );
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        {user.state === 1 ? "禁用账户" : "启用账户"}
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {user.state === 1
          ? "你正在禁用" + user.username + "，确认继续吗？"
          : "你正在启用" + user.username + "，确认继续吗？"}
      </DialogContent>
      <DialogActions>
        <Button
          color={user.state === 1 ? "error" : "success"}
          size="small"
          onClick={() => {
            submitEdition();
          }}
        >
          {user.state === 1 ? "禁用" : "启用"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
