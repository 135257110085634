import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";

export default function DeleteProjectDialog({ open, project, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  const submitDeletion = () => {
    const deleteParams = {
      projectIdList: project.id,
    };
    _axios.delete("/rbac/project", { params: deleteParams }).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("删除项目成功！", { variant: "success" });
      } else {
        enqueueSnackbar("删除项目失败！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        删除项目
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {"你正在删除" +
          project.name +
          "，将会删除该项目相关的所有数据，确认删除吗？"}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          size="small"
          onClick={() => {
            submitDeletion();
          }}
        >
          {"删除"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
