import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";
import DateTimeSelect from "../frame/DateTimeSelect";

export default function GenerateTokenDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [expiration, setExpiration] = useState(new Date());
  const [token, setToken] = useState("");

  const getToken = () => {
    const postObj = {
      userId: user.id,
      password,
      loginMethod: 2,
      expiration,
    };
    _axios.post("/rbac/auth/do-authorization", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setToken(resp.data.data);
      } else {
        enqueueSnackbar("生成令牌失败！", { variant: "error" });
      }
    });
  };

  useEffect(() => {
    setPassword("");
    setToken("");
  }, [user]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        生成令牌
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" margin="normal" fullWidth>
          <InputLabel>密码</InputLabel>
          <Input
            type={passwordVisibility ? "text" : "password"}
            value={password}
            onInput={(event) => {
              setPassword(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setPasswordVisibility(!passwordVisibility);
                  }}
                >
                  {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <DateTimeSelect
          variant="standard"
          fullWidth
          margin="normal"
          label={"过期时间"}
          value={expiration}
          onChange={(value) => {
            console.log(value);
            setExpiration(value);
          }}
        />
      </DialogContent>
      {token === "" ? (
        <DialogActions>
          <Button
            color="success"
            size="small"
            onClick={() => {
              getToken();
            }}
          >
            {"生成令牌"}
          </Button>
        </DialogActions>
      ) : (
        <DialogContent>
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {token}
          </Typography>
        </DialogContent>
      )}
    </Dialog>
  );
}
