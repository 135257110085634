import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { format } from "date-fns";

export default function LogServerList() {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [keyWord, setKeyWord] = useState("");
  const [logList, setLogList] = useState([]);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ pageSize: 100, pageNum: 1 });
  const [pageSizeOptions] = useState([10, 25, 50, 100]);

  const logListInit = () => {
    const getParams = {
      pageSize: pagination.pageSize,
      pageNum: pagination.pageNum,
      projectIdList: selectedProject.id,
      keyWord: keyWord,
    };
    _axios.get("/log/log", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setLogList(resp.data.data.logList);
        setPages(resp.data.data.pages);
        setTotal(resp.data.data.total);
      } else {
        enqueueSnackbar("获取日志列表失败！", { variant: "error" });
      }
    });
  };

  const changePageSize = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    });
  };

  const changePageNum = (pageNum) => {
    setPagination({
      ...pagination,
      pageNum,
    });
  };

  useEffect(() => {
    logListInit();
  }, [selectedProject, keyWord, pagination]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} display={"flex"} alignItems={"center"}>
        <Typography>{"每页显示 "}</Typography>
        <Select
          variant="standard"
          value={pagination.pageSize}
          onChange={(event) => {
            changePageSize(event.target.value);
          }}
          sx={{ mx: 1 }}
        >
          {pageSizeOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Typography>{" 条，共 " + total + " 条记录"}</Typography>
        <Divider orientation="vertical" sx={{ mx: 2 }} />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          variant="standard"
          fullWidth
          placeholder={"关键词查询"}
          InputProps={{
            endAdornment: <Search />,
          }}
          value={keyWord}
          onInput={(event) => {
            setKeyWord(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <List>
          {logList.map((log, index) => (
            <LogListItem key={index} log={log} />
          ))}
        </List>
      </Grid>
      {pages > 0 && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Pagination
            count={pages}
            page={pagination.pageNum}
            shape="rounded"
            onChange={(event, value) => {
              changePageNum(value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

function LogListItem({ log }) {
  return (
    <ListItem>
      <ListItemText
        primary={format(log.logTime, "yyyy/MM/dd HH:mm:ss")}
        secondary={log.message}
        secondaryTypographyProps={{ sx: { wordWrap: "break-word" } }}
      />
    </ListItem>
  );
}
