import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";

export default function EditUserDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");

  const infoInit = () => {
    setUsername(user.hasOwnProperty("username") ? user.username : "");
    setPhone(user.hasOwnProperty("phone") ? user.phone : "");
  };

  const submitEdition = () => {
    const putObj = {
      putUserEntList: [{ id: user.id, username, phone }],
    };
    _axios.put("/rbac/user", putObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("修改用户信息成功！", { variant: "success" });
      } else {
        enqueueSnackbar("修改用户信息失败！", { variant: "error" });
      }
    });
  };

  useEffect(() => {
    infoInit();
  }, [user]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        编辑用户信息
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"用户名"}
          value={username}
          onInput={(event) => {
            setUsername(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"手机号码"}
          value={phone}
          onInput={(event) => {
            setPhone(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            submitEdition();
          }}
        >
          {"修改"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
