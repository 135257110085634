import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import _axios from "../../plugin/axios";
import { useSelector } from "react-redux";

export default function AddUserDialog({ open, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");

  const submitAddition = () => {
    const userPostObj = {
      postUserEntList: [{ username, phone, password: "000000", state: 0 }],
    };
    _axios.post("/rbac/user", userPostObj).then((userResp) => {
      if (userResp.data.code === "00") {
        const userProjectPostObj = {
          postUserProjectRelList: [
            {
              userId: userResp.data.data.toString(),
              projectId: selectedProject.id,
            },
          ],
        };
        _axios
          .post("/rbac/user-project", userProjectPostObj)
          .then((userProjectResp) => {
            if (userProjectResp.data.code === "00") {
              closeDialog();
              enqueueSnackbar("添加用户成功！默认密码为000000", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("添加用户失败！", { variant: "error" });
            }
          });
      } else {
        enqueueSnackbar("添加用户失败！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        添加用户
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"用户名"}
          value={username}
          onInput={(event) => {
            setUsername(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"手机号码"}
          value={phone}
          onInput={(event) => {
            setPhone(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          size="small"
          onClick={() => {
            submitAddition();
          }}
        >
          {"添加"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
