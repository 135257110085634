import { useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import UserCardServerList from "../components/userManager/UserCardServerList";
import AddUserDialog from "../components/userManager/AddUserDialog";
import EditUserDialog from "../components/userManager/EditUserDialog";
import GenerateTokenDialog from "../components/userManager/GenerateTokenDialog";
import ResetPasswordDialog from "../components/userManager/ResetPasswordDialog";
import ChangeUserStateDialog from "../components/userManager/ChangeUserStateDialog";
import DeleteUserDialog from "../components/userManager/DeleteUserDialog";
import EditRoleDialog from "../components/userManager/EditRoleDialog";
import EditProjectDialog from "../components/userManager/EditProjectDialog";

export default function UserManagerView() {
  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [user, setUser] = useState({});
  const [addUserFlag, setAddUserFlag] = useState(false);
  const [editUserFlag, setEditUserFlag] = useState(false);
  const [generateTokenFlag, setGenerateTokenFlag] = useState(false);
  const [resetPasswordFlag, setResetPasswordFlag] = useState(false);
  const [changeUserStateFlag, setChangeUserStateFlag] = useState(false);
  const [editRoleFlag, setEditRoleFlag] = useState(false);
  const [editProjectFlag, setEditProjectFlag] = useState(false);
  const [deleteUserFlag, setDeleteUserFlag] = useState(false);

  const addUser = () => {
    setAddUserFlag(true);
  };

  const finishUserAddition = () => {
    setAddUserFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
  };

  const editUser = (user) => {
    setUser(user);
    setEditUserFlag(true);
  };

  const finishUserEdition = () => {
    setEditUserFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const generateToken = (user) => {
    setUser(user);
    setGenerateTokenFlag(true);
  };

  const finishTokenGeneration = () => {
    setGenerateTokenFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const resetPassword = (user) => {
    setUser(user);
    setResetPasswordFlag(true);
  };

  const finishPasswordResetation = () => {
    setResetPasswordFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const changeUserState = (user) => {
    setUser(user);
    setChangeUserStateFlag(true);
  };

  const finishUserStateChange = () => {
    setChangeUserStateFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const editRole = (user) => {
    setUser(user);
    setEditRoleFlag(true);
  };

  const finishRoleEdition = () => {
    setEditRoleFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const editProject = (user) => {
    setUser(user);
    setEditProjectFlag(true);
  };

  const finishProjectEdition = () => {
    setEditProjectFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  const deleteUser = (user) => {
    setUser(user);
    setDeleteUserFlag(true);
  };

  const finishUserDeletion = () => {
    setDeleteUserFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setUser({});
  };

  return (
    <AppLayout>
      <UserCardServerList
        refreshListFlag={refreshListFlag}
        addUser={addUser}
        editUser={editUser}
        generateToken={generateToken}
        resetPassword={resetPassword}
        changeUserState={changeUserState}
        editRole={editRole}
        editProject={editProject}
        deleteUser={deleteUser}
      />
      <AddUserDialog open={addUserFlag} closeDialog={finishUserAddition} />
      <EditUserDialog
        open={editUserFlag}
        user={user}
        closeDialog={finishUserEdition}
      />
      <GenerateTokenDialog
        open={generateTokenFlag}
        user={user}
        closeDialog={finishTokenGeneration}
      />
      <ResetPasswordDialog
        open={resetPasswordFlag}
        user={user}
        closeDialog={finishPasswordResetation}
      />
      <ChangeUserStateDialog
        open={changeUserStateFlag}
        user={user}
        closeDialog={finishUserStateChange}
      />
      <EditRoleDialog
        open={editRoleFlag}
        user={user}
        closeDialog={finishRoleEdition}
      />
      <EditProjectDialog
        open={editProjectFlag}
        user={user}
        closeDialog={finishProjectEdition}
      />
      <DeleteUserDialog
        open={deleteUserFlag}
        user={user}
        closeDialog={finishUserDeletion}
      />
    </AppLayout>
  );
}
