import { Box } from '@mui/material'
import LoginCard from '../components/login/LoginCard'

export default function LoginView() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <LoginCard />
    </Box>
  )
}
