import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { removeToken } from "../../plugin/store/slices/tokenSlice";
import ElevationScroll from "../frame/ElevationScroll";
import { setDrawerState } from "../../plugin/store/slices/drawerStateSlice";
import { removeUserInfo } from "../../plugin/store/slices/userInfoSlice";
import ChangePasswordDialog from "./ChangePasswordDialog";
import { setSelectedProject } from "../../plugin/store/slices/selectedProjectSlice";
import {
  AccountCircle,
  ArrowDropDown,
  ChangeCircle,
  FormatListBulleted,
  Logout,
  MoreVert,
} from "@mui/icons-material";
import _axios from "../../plugin/axios";
import { enqueueSnackbar } from "notistack";

const drawerWidth = 240;

function openedMixin(theme) {
  return {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  };
}

function closedMixin(theme) {
  return {
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  };
}

const CollapsibleDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "drawerState",
})(({ theme, drawerState }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(drawerState && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!drawerState && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AppLayout({ children }) {
  // redux变量区
  const routesList = useSelector((state) => state.routesList);
  const selectedProject = useSelector((state) => state.selectedProject);
  const drawerState = useSelector((state) => state.drawerState);
  const userInfo = useSelector((state) => state.userInfo);
  const dispatch = useDispatch();

  // router变量区
  const location = useLocation();

  // state变量区
  const [currentRouteTitle, setCurrentRouteTitle] = useState("");

  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [projectAnchorEl, setProjectAnchorEl] = useState(null);

  const [projectOptions, setProjectOptions] = useState([]);

  const [changePasswordDialogState, setChangePasswordDialogState] =
    useState(false);

  // 改变侧边栏打开和关闭状态
  const changeDrawerState = () => {
    dispatch(setDrawerState(!drawerState.value));
  };

  // 获取当前路由对应的路由名称
  const currentRouteTitleInit = () => {
    routesList.value.forEach((route) => {
      if (route.path === location.pathname) setCurrentRouteTitle(route.title);
    });
  };

  const projectOptionsInit = () => {
    const getParams = {
      pageSize: 100,
      pageNum: 1,
    };
    _axios.get("/rbac/project", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        if (selectedProject.id === 0) {
          dispatch(setSelectedProject(resp.data.data.projectList[0]));
        }
        setProjectOptions(resp.data.data.projectList);
      } else {
        enqueueSnackbar("获取项目列表时出错！", { variant: "error" });
      }
    });
  };

  const openProjectMenu = (event) => {
    setProjectAnchorEl(event.currentTarget);
  };

  const closeProjectMenu = () => {
    setProjectAnchorEl(null);
  };

  const changeProject = (project) => {
    dispatch(setSelectedProject(project));
    closeProjectMenu();
  };

  const openUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };

  const closeUserMenu = () => {
    setUserAnchorEl(null);
  };

  const changePassword = () => {
    setChangePasswordDialogState(true);
  };

  const finishPasswordChange = () => {
    setChangePasswordDialogState(false);
  };

  const logout = () => {
    dispatch(removeToken());
    dispatch(removeUserInfo());
  };

  useEffect(() => {
    currentRouteTitleInit();
    projectOptionsInit();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <ElevationScroll>
        <AppBar
          color="default"
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                changeDrawerState();
              }}
              sx={{ mr: 3 }}
            >
              <FormatListBulleted />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentRouteTitle}
            </Typography>

            <Button
              id="project-menu-button"
              color="inherit"
              sx={{ mr: 2, textTransform: "none" }}
              endIcon={<ArrowDropDown />}
              aria-controls={projectAnchorEl ? "project-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={projectAnchorEl ? "project-menu" : undefined}
              onClick={(event) => {
                openProjectMenu(event);
              }}
            >
              {selectedProject.name}
            </Button>
            <Menu
              id="project-menu"
              anchorEl={projectAnchorEl}
              open={Boolean(projectAnchorEl)}
              onClose={() => {
                closeProjectMenu();
              }}
              MenuListProps={{
                "aria-labelledby": "project-menu-button",
              }}
            >
              <MenuList dense>
                {projectOptions.map((projectOption, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      changeProject(projectOption);
                    }}
                  >
                    {projectOption.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>

            <Button
              id="user-menu-button"
              edge="start"
              color="inherit"
              startIcon={<AccountCircle />}
              endIcon={<MoreVert />}
              aria-controls={userAnchorEl ? "user-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={userAnchorEl ? "user-menu" : undefined}
              onClick={(event) => {
                openUserMenu(event);
              }}
            >
              {userInfo.username}
            </Button>
            <Menu
              id="user-menu"
              anchorEl={userAnchorEl}
              open={Boolean(userAnchorEl)}
              onClose={() => {
                closeUserMenu();
              }}
              MenuListProps={{
                "aria-labelledby": "user-menu-button",
              }}
            >
              <MenuList dense>
                <MenuItem
                  onClick={() => {
                    changePassword();
                  }}
                >
                  <ListItemIcon>
                    <ChangeCircle />
                  </ListItemIcon>
                  <ListItemText>修改密码</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    logout();
                  }}
                >
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText>退出登录</ListItemText>
                </MenuItem>
              </MenuList>
            </Menu>
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      <CollapsibleDrawer variant="permanent" drawerState={drawerState.value}>
        <Toolbar />
        <List>
          {routesList.value
            .filter((route) => {
              return route.element;
            })
            .map((route, index) => (
              <NavLink
                key={index}
                to={route.path}
                style={{ textDecoration: "none" }}
              >
                {({ isActive, isPending }) => (
                  <ListItem sx={{ px: 0.5, py: 0.25 }}>
                    {drawerState.value ? (
                      <ListItemButton
                        sx={{
                          borderRadius: 1,
                          backgroundColor: isActive
                            ? "#E7EEF9"
                            : isPending
                            ? "#F6F6F6"
                            : "",
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 3,
                            color: isActive ? "#4E83D1" : "#757575",
                          }}
                        >
                          {route.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={route.title}
                          sx={{ color: isActive ? "#4E83D1" : "#757575" }}
                        />
                      </ListItemButton>
                    ) : (
                      <Tooltip title={route.title} placement="right">
                        <ListItemButton
                          sx={{
                            borderRadius: 1,
                            backgroundColor: isActive
                              ? "#E7EEF9"
                              : isPending
                              ? "#F6F6F6"
                              : "",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 3,
                              color: isActive ? "#4E83D1" : "#757575",
                            }}
                          >
                            {route.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={route.title}
                            sx={{ color: isActive ? "#4E83D1" : "#757575" }}
                          />
                        </ListItemButton>
                      </Tooltip>
                    )}
                  </ListItem>
                )}
              </NavLink>
            ))}
        </List>
      </CollapsibleDrawer>
      <Container component="main" sx={{ py: 4 }}>
        <Toolbar />
        {children}
      </Container>
      <ChangePasswordDialog
        dialogState={changePasswordDialogState}
        userInfo={userInfo}
        logout={logout}
        finishEdition={finishPasswordChange}
      />
    </Box>
  );
}
