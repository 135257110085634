import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { Add, Delete, Inbox, Search } from "@mui/icons-material";
import { getColorFromString } from "../../plugin/utils";

export default function ProjectCardServerList({
  refreshListFlag,
  addProject,
  editProject,
  deleteProject,
}) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [keyWord, setKeyWord] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ pageSize: 100, pageNum: 1 });
  const [pageSizeOptions] = useState([10, 25, 50, 100]);

  const projectListInit = () => {
    const getParams = {
      pageSize: pagination.pageSize,
      pageNum: pagination.pageNum,
      keyWord: keyWord,
    };
    _axios.get("/rbac/project", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setProjectList(resp.data.data.projectList);
        setPages(resp.data.data.pages);
        setTotal(resp.data.data.total);
      } else {
        enqueueSnackbar("获取项目列表失败！", { variant: "error" });
      }
    });
  };

  const changePageSize = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    });
  };

  const changePageNum = (pageNum) => {
    setPagination({
      ...pagination,
      pageNum,
    });
  };

  useEffect(() => {
    projectListInit();
  }, [refreshListFlag, keyWord, pagination]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} display={"flex"} alignItems={"center"}>
        <Typography>{"每页显示 "}</Typography>
        <Select
          variant="standard"
          value={pagination.pageSize}
          onChange={(event) => {
            changePageSize(event.target.value);
          }}
          sx={{ mx: 1 }}
        >
          {pageSizeOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Typography>{" 条，共 " + total + " 条记录"}</Typography>
        <Divider orientation="vertical" sx={{ mx: 2 }} />
        <Button
          startIcon={<Add />}
          onClick={() => {
            addProject();
          }}
        >
          {"添加项目"}
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          variant="standard"
          fullWidth
          placeholder={"关键词查询"}
          InputProps={{
            endAdornment: <Search />,
          }}
          value={keyWord}
          onInput={(event) => {
            setKeyWord(event.target.value);
          }}
        />
      </Grid>
      {projectList.map((project, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <ProjectCard
            project={project}
            editProject={editProject}
            deleteProject={deleteProject}
          />
        </Grid>
      ))}
      {pages > 0 && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Pagination
            count={pages}
            page={pagination.pageNum}
            shape="rounded"
            onChange={(event, value) => {
              changePageNum(value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

function ProjectCard({ project, editProject, deleteProject }) {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar
            variant="rounded"
            sx={{ width: "30px", height: "30px", bgcolor: "#4E83D1" }}
          >
            {project.id}
          </Avatar>
        }
        title={project.name}
        subheader={project.baseUrl}
        action={
          <Chip
            variant="outlined"
            size="small"
            color={getColorFromString(project.field)}
            label={project.field}
          />
        }
      ></CardHeader>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {project.company}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.location}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {project.contact}
        </Typography>
      </CardContent>
      <CardActions>
        <Tooltip arrow placement="top" title="编辑项目信息">
          <IconButton
            size="small"
            onClick={() => {
              editProject(project);
            }}
          >
            <Inbox fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Box flex={1}></Box>
        <Tooltip arrow placement="top" title="删除项目">
          <IconButton
            size="small"
            onClick={() => {
              deleteProject(project);
            }}
          >
            <Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
