import { createSlice } from "@reduxjs/toolkit";

export const selectedProjectSlice = createSlice({
  name: "selectedProject",
  initialState: {
    id: 0,
    name: "",
    baseUrl: "",
    company: "",
    contact: "",
    location: "",
    field: "",
  },
  reducers: {
    setSelectedProject: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.baseUrl = action.payload.baseUrl;
      state.company = action.payload.company;
      state.contact = action.payload.contact;
      state.location = action.payload.location;
      state.field = action.payload.field;
    },
  },
});

export const { setSelectedProject } = selectedProjectSlice.actions;

export default selectedProjectSlice.reducer;
