import {
  ChevronLeft,
  ChevronRight,
  Close,
  Security,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { blue, green, orange } from "@mui/material/colors";
import { infinite } from "../../plugin/utils";

export default function EditPermissionDialog({ open, role, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [permissionList, setPermissionList] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedPermissionOptions, setSelectedPermissionOptions] = useState(
    []
  );
  const [roleRouteList, setRoleRouteList] = useState([{ title: "所有权限" }]);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState(0);
  const [rolePermissionList, setRolePermissionList] = useState([]);
  const [selectedRolePermissionOptions, setSelectedRolePermissionOptions] =
    useState([]);

  const permissionListInit = () => {
    const permissionGetParams = {
      pageSize: infinite,
      pageNum: 1,
      projectIdList: selectedProject.id,
    };
    _axios
      .get("/rbac/permission", { params: permissionGetParams })
      .then((permissionResp) => {
        if (permissionResp.data.code === "00") {
          if (roleRouteList[selectedRouteIndex].id) {
            const routePermissionGetParams = {
              pageSize: infinite,
              pageNum: 1,
              routeIdList: roleRouteList[selectedRouteIndex].id,
            };
            _axios
              .get("/rbac/route-permission", {
                params: routePermissionGetParams,
              })
              .then((routePermissionResp) => {
                if (routePermissionResp.data.code === "00") {
                  setPermissionList(
                    permissionResp.data.data.permissionList.filter((option) => {
                      return (
                        routePermissionResp.data.data.routePermissionList.findIndex(
                          (subOption) => {
                            return subOption.permissionId === option.id;
                          }
                        ) !== -1
                      );
                    })
                  );
                } else {
                  enqueueSnackbar("获取权限列表失败！", { variant: "error" });
                }
              });
          } else {
            setPermissionList(permissionResp.data.data.permissionList);
          }
        } else {
          enqueueSnackbar("获取权限列表失败！", { variant: "error" });
        }
      });
  };

  const roleRouteListInit = () => {
    if (role.id) {
      const roleRouteGetParams = {
        pageSize: infinite,
        pageNum: 1,
        roleIdList: role.id,
      };
      _axios
        .get("/rbac/role-route", { params: roleRouteGetParams })
        .then((roleRouteResp) => {
          if (roleRouteResp.data.code === "00") {
            if (roleRouteResp.data.data.total <= 0) {
              setRoleRouteList([{ title: "所有权限" }]);
            } else {
              let routeIdList = [];
              roleRouteResp.data.data.roleRouteList.forEach((roleRoute) => {
                routeIdList.push(roleRoute.routeId);
              });
              const routeGetParams = {
                pageSize: infinite,
                pageNum: 1,
                routeIdList: routeIdList.toString(),
              };
              _axios
                .get("/rbac/route", { params: routeGetParams })
                .then((resp) => {
                  if (resp.data.code === "00") {
                    setRoleRouteList([
                      { title: "所有权限" },
                      ...resp.data.data.routeList,
                    ]);
                  } else {
                    enqueueSnackbar("获取当前角色的菜单列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前角色的菜单列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setRoleRouteList([]);
    }
  };

  const rolePermissionListInit = () => {
    if (role.id) {
      const rolePermissionGetParams = {
        pageSize: infinite,
        pageNum: 1,
        roleIdList: role.id,
      };
      _axios
        .get("/rbac/role-permission", { params: rolePermissionGetParams })
        .then((rolePermissionResp) => {
          if (rolePermissionResp.data.code === "00") {
            if (rolePermissionResp.data.data.total <= 0) {
              setRolePermissionList([]);
            } else {
              let permissionIdList = [];
              rolePermissionResp.data.data.rolePermissionList.forEach(
                (rolePermission) => {
                  permissionIdList.push(rolePermission.permissionId);
                }
              );
              const permissionGetParams = {
                pageSize: infinite,
                pageNum: 1,
                permissionIdList: permissionIdList.toString(),
              };
              _axios
                .get("/rbac/permission", { params: permissionGetParams })
                .then((permissionResp) => {
                  if (permissionResp.data.code === "00") {
                    if (roleRouteList[selectedRouteIndex].id) {
                      const routePermissionGetParams = {
                        pageSize: infinite,
                        pageNum: 1,
                        routeIdList: roleRouteList[selectedRouteIndex].id,
                      };
                      _axios
                        .get("/rbac/route-permission", {
                          params: routePermissionGetParams,
                        })
                        .then((routePermissionResp) => {
                          if (routePermissionResp.data.code === "00") {
                            setRolePermissionList(
                              permissionResp.data.data.permissionList.filter(
                                (option) => {
                                  return (
                                    routePermissionResp.data.data.routePermissionList.findIndex(
                                      (subOption) => {
                                        return (
                                          subOption.permissionId === option.id
                                        );
                                      }
                                    ) !== -1
                                  );
                                }
                              )
                            );
                          } else {
                            enqueueSnackbar("获取当前菜单的权限列表失败！", {
                              variant: "error",
                            });
                          }
                        });
                    } else {
                      setRolePermissionList(
                        permissionResp.data.data.permissionList
                      );
                    }
                  } else {
                    enqueueSnackbar("获取当前菜单的权限列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前菜单的权限列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setRolePermissionList([]);
    }
  };

  const permissionOptionsInit = () => {
    setPermissionOptions(
      permissionList.filter((option) => {
        return (
          rolePermissionList.findIndex((subOption) => {
            return subOption.id === option.id;
          }) === -1
        );
      })
    );
  };

  const selectAllPermissionOptions = () => {
    if (selectedPermissionOptions.length !== permissionOptions.length) {
      setSelectedPermissionOptions(permissionOptions);
    } else {
      setSelectedPermissionOptions([]);
    }
  };

  const selectPermissionOption = (permission) => {
    let tmpSelectedPermissionOptions = selectedPermissionOptions;
    const permissionIndexInSelectedPermissionOptions =
      selectedPermissionOptions.findIndex((option) => {
        return option.id === permission.id;
      });
    if (permissionIndexInSelectedPermissionOptions !== -1) {
      tmpSelectedPermissionOptions.splice(
        permissionIndexInSelectedPermissionOptions,
        1
      );
    } else {
      tmpSelectedPermissionOptions.push(permission);
    }
    setSelectedPermissionOptions([...tmpSelectedPermissionOptions]);
  };

  const addRolePermission = () => {
    let rolePermissionRelList = [];
    selectedPermissionOptions.forEach((option) => {
      rolePermissionRelList.push({
        roleId: role.id,
        permissionId: option.id,
      });
    });
    const postObj = {
      postRolePermissionRelList: rolePermissionRelList,
    };
    _axios.post("/rbac/role-permission", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setRefreshListFlag(refreshListFlag + 1);
        enqueueSnackbar("向当前菜单添加权限成功！", { variant: "success" });
      } else {
        enqueueSnackbar("向当前菜单添加权限失败！", { variant: "error" });
      }
    });
  };

  const deleteRolePermission = () => {
    let permissionIdList = [];
    selectedRolePermissionOptions.forEach((option) => {
      permissionIdList.push(option.id);
    });
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      roleIdList: role.id,
      permissionIdList: permissionIdList.toString(),
    };
    _axios
      .get("/rbac/role-permission", { params: getParams })
      .then((getResp) => {
        if (getResp.data.code === "00") {
          let rolePermissionIdList = [];
          getResp.data.data.rolePermissionList.forEach((rolePermission) => {
            rolePermissionIdList.push(rolePermission.id);
          });
          const deleteParams = {
            rolePermissionIdList: rolePermissionIdList.toString(),
          };
          _axios
            .delete("/rbac/role-permission", { params: deleteParams })
            .then((deleteResp) => {
              if (deleteResp.data.code === "00") {
                setRefreshListFlag(refreshListFlag + 1);
                enqueueSnackbar("移除当前菜单的权限成功！", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("移除当前菜单的权限失败！", {
                  variant: "error",
                });
              }
            });
        } else {
          enqueueSnackbar("移除当前菜单的权限失败！", { variant: "error" });
        }
      });
  };

  const selectAllRolePermissionOptions = () => {
    if (selectedRolePermissionOptions.length !== rolePermissionList.length) {
      setSelectedRolePermissionOptions(rolePermissionList);
    } else {
      setSelectedRolePermissionOptions([]);
    }
  };

  const selectRolePermissionOption = (rolePermission) => {
    let tmpSelectedRolePermissionOptions = selectedRolePermissionOptions;
    const rolePermissionIndexInSelectedRolePermissionOptions =
      selectedRolePermissionOptions.findIndex((option) => {
        return option.id === rolePermission.id;
      });
    if (rolePermissionIndexInSelectedRolePermissionOptions !== -1) {
      tmpSelectedRolePermissionOptions.splice(
        rolePermissionIndexInSelectedRolePermissionOptions,
        1
      );
    } else {
      tmpSelectedRolePermissionOptions.push(rolePermission);
    }
    setSelectedRolePermissionOptions([...tmpSelectedRolePermissionOptions]);
  };

  useEffect(() => {
    permissionListInit();
  }, [selectedProject, selectedRouteIndex]);

  useEffect(() => {
    rolePermissionListInit();
    setSelectedPermissionOptions([]);
    setSelectedRolePermissionOptions([]);
  }, [role, selectedRouteIndex, refreshListFlag]);

  useEffect(() => {
    roleRouteListInit();
    setRoleRouteList([{ title: "所有权限" }]);
    setSelectedRouteIndex(0);
  }, [role]);

  useEffect(() => {
    permissionOptionsInit();
  }, [permissionList, rolePermissionList]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        配置权限
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} columns={11}>
          <Grid item xs={11}>
            <Tabs value={selectedRouteIndex}>
              {roleRouteList.map((roleRoute, index) => (
                <Tab
                  key={index}
                  onClick={() => {
                    setSelectedRouteIndex(index);
                  }}
                  label={roleRoute.title}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px" }}
                  >
                    <Security fontSize="inherit" />
                  </Avatar>
                }
                title={"未选择权限"}
                subheader={
                  selectedPermissionOptions.length +
                  "/" +
                  permissionOptions.length
                }
                action={
                  <Checkbox
                    disabled={permissionOptions.length <= 0}
                    checked={
                      permissionOptions.length > 0 &&
                      selectedPermissionOptions.length ===
                        permissionOptions.length
                    }
                    indeterminate={
                      permissionOptions.length > 0 &&
                      selectedPermissionOptions.length > 0 &&
                      selectedPermissionOptions.length !==
                        permissionOptions.length
                    }
                    onClick={() => {
                      selectAllPermissionOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {permissionOptions.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={
                          option.inspectable === 0
                            ? {
                                width: "30px",
                                height: "30px",
                                bgcolor: orange[900],
                              }
                            : {
                                width: "30px",
                                height: "30px",
                                bgcolor: green[900],
                              }
                        }
                      >
                        <Security fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.method + ": " + option.uri}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedPermissionOptions.findIndex(
                            (selectedPermissionOption) => {
                              return option.id === selectedPermissionOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectPermissionOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="small"
              disabled={selectedPermissionOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                addRolePermission();
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={selectedRolePermissionOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                deleteRolePermission();
              }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px", bgcolor: blue["700"] }}
                  >
                    <Security fontSize="inherit" />
                  </Avatar>
                }
                title={"已选择权限"}
                subheader={
                  selectedRolePermissionOptions.length +
                  "/" +
                  rolePermissionList.length
                }
                action={
                  <Checkbox
                    disabled={rolePermissionList.length <= 0}
                    checked={
                      rolePermissionList.length > 0 &&
                      selectedRolePermissionOptions.length ===
                        rolePermissionList.length
                    }
                    indeterminate={
                      rolePermissionList.length > 0 &&
                      selectedRolePermissionOptions.length > 0 &&
                      selectedRolePermissionOptions.length !==
                        rolePermissionList.length
                    }
                    onClick={() => {
                      selectAllRolePermissionOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {rolePermissionList.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={
                          option.inspectable === 0
                            ? {
                                width: "30px",
                                height: "30px",
                                bgcolor: orange[900],
                              }
                            : {
                                width: "30px",
                                height: "30px",
                                bgcolor: green[900],
                              }
                        }
                      >
                        <Security fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.method + ": " + option.uri}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedRolePermissionOptions.findIndex(
                            (selectedRolePermissionOption) => {
                              return (
                                option.id === selectedRolePermissionOption.id
                              );
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectRolePermissionOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
