import AppLayout from "../components/layout/AppLayout";
import LogServerList from "../components/logManager/LogServerList";

export default function LogManagerView() {
  return (
    <AppLayout>
      <LogServerList />
    </AppLayout>
  );
}
