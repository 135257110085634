import { createSlice } from '@reduxjs/toolkit'

export const drawerStateSlice = createSlice({
  name: 'drawerState',
  initialState: {
    value: false,
  },
  reducers: {
    setDrawerState: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setDrawerState } = drawerStateSlice.actions

export default drawerStateSlice.reducer
