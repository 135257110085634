import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import _axios from "../../plugin/axios";
import { useSelector } from "react-redux";

export default function AddPermissionDialog({ open, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [methodOptions] = useState([
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "DELETE", value: "DELETE" },
  ]);
  const [method, setMethod] = useState("");
  const [uri, setUri] = useState("");
  const [description, setDescription] = useState("");
  const [inspectableOptions] = useState([
    { label: "无需令牌校验", value: 0 },
    { label: "要求令牌校验", value: 1 },
  ]);
  const [inspectable, setInspectable] = useState("");

  const submitAddition = () => {
    const postObj = {
      postPermissionEntList: [
        {
          method,
          uri,
          description,
          inspectable,
          projectId: selectedProject.id,
        },
      ],
    };
    _axios.post("/rbac/permission", postObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("添加权限成功！", { variant: "success" });
      } else {
        enqueueSnackbar("添加权限失败！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        添加权限
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>{"请求方式"}</InputLabel>
          <Select
            value={method}
            onChange={(event) => {
              setMethod(event.target.value);
            }}
          >
            {methodOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"接口地址"}
          value={uri}
          onInput={(event) => {
            setUri(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"接口描述"}
          value={description}
          onInput={(event) => {
            setDescription(event.target.value);
          }}
        />
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>{"校验要求"}</InputLabel>
          <Select
            value={inspectable}
            onChange={(event) => {
              setInspectable(event.target.value);
            }}
          >
            {inspectableOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          size="small"
          onClick={() => {
            submitAddition();
          }}
        >
          {"添加"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
