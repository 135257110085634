import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import _axios from "../../plugin/axios";
import { useSelector } from "react-redux";

export default function AddRouteDialog({ open, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");

  const submitAddition = () => {
    const postObj = {
      postRouteEntList: [{ title, path, projectId: selectedProject.id }],
    };
    _axios.post("/rbac/route", postObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("添加菜单成功！", { variant: "success" });
      } else {
        enqueueSnackbar("添加菜单失败！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        添加菜单
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"菜单名称"}
          value={title}
          onInput={(event) => {
            setTitle(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"菜单路径"}
          value={path}
          onInput={(event) => {
            setPath(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          size="small"
          onClick={() => {
            submitAddition();
          }}
        >
          {"添加"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
