import { useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import PermissionCardServerList from "../components/permissionManager/PermissionCardServerList";
import AddPermissionDialog from "../components/permissionManager/AddPermissionDialog";
import EditPermissionDialog from "../components/permissionManager/EditPermissionDialog";
import DeletePermissionDialog from "../components/permissionManager/DeletePermissionDialog";

export default function PermissionManagerView() {
  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [permission, setPermission] = useState({});
  const [addPermissionFlag, setAddPermissionFlag] = useState(false);
  const [editPermissionFlag, setEditPermissionFlag] = useState(false);
  const [deletePermissionFlag, setDeletePermissionFlag] = useState(false);

  const addPermission = () => {
    setAddPermissionFlag(true);
  };

  const finishPermissionAddition = () => {
    setAddPermissionFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
  };

  const editPermission = (permission) => {
    setPermission(permission);
    setEditPermissionFlag(true);
  };

  const finishPermissionEdition = () => {
    setEditPermissionFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setPermission({});
  };

  const deletePermission = (permission) => {
    setPermission(permission);
    setDeletePermissionFlag(true);
  };

  const finishPermissionDeletion = () => {
    setDeletePermissionFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setPermission({});
  };

  return (
    <AppLayout>
      <PermissionCardServerList
        refreshListFlag={refreshListFlag}
        addPermission={addPermission}
        editPermission={editPermission}
        deletePermission={deletePermission}
      />
      <AddPermissionDialog
        open={addPermissionFlag}
        closeDialog={finishPermissionAddition}
      />
      <EditPermissionDialog
        open={editPermissionFlag}
        permission={permission}
        closeDialog={finishPermissionEdition}
      />
      <DeletePermissionDialog
        open={deletePermissionFlag}
        permission={permission}
        closeDialog={finishPermissionDeletion}
      />
    </AppLayout>
  );
}
