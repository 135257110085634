import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";

export default function ResetPasswordDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  const submitEdition = () => {
    const putObj = {
      putUserEntList: [{ id: user.id, password: "000000" }],
    };
    _axios.put("/rbac/user", putObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("重置密码成功！当前密码为000000", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("重置密码失败！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        重置密码
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {"你正在将" + user.username + "的密码重置为000000，确认继续吗？"}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            submitEdition();
          }}
        >
          {"重制"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
