import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";

export default function EditPermissionDialog({
  open,
  permission,
  closeDialog,
}) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [methodOptions] = useState([
    { label: "GET", value: "GET" },
    { label: "POST", value: "POST" },
    { label: "PUT", value: "PUT" },
    { label: "DELETE", value: "DELETE" },
  ]);
  const [method, setMethod] = useState("");
  const [uri, setUri] = useState("");
  const [description, setDescription] = useState("");
  const [inspectableOptions] = useState([
    { label: "无需令牌校验", value: 0 },
    { label: "要求令牌校验", value: 1 },
  ]);
  const [inspectable, setInspectable] = useState("");

  const infoInit = () => {
    setMethod(permission.hasOwnProperty("method") ? permission.method : "");
    setUri(permission.hasOwnProperty("uri") ? permission.uri : "");
    setDescription(
      permission.hasOwnProperty("description") ? permission.description : ""
    );
    setInspectable(
      permission.hasOwnProperty("inspectable") ? permission.inspectable : ""
    );
  };

  const submitEdition = () => {
    const putObj = {
      putPermissionEntList: [
        { id: permission.id, method, uri, description, inspectable },
      ],
    };
    _axios.put("/rbac/permission", putObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("修改权限信息成功！", { variant: "success" });
      } else {
        enqueueSnackbar("修改权限信息失败！", { variant: "error" });
      }
    });
  };

  useEffect(() => {
    infoInit();
  }, [permission]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        编辑权限信息
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>{"请求方式"}</InputLabel>
          <Select
            value={method}
            onChange={(event) => {
              setMethod(event.target.value);
            }}
          >
            {methodOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"接口地址"}
          value={uri}
          onInput={(event) => {
            setUri(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"接口描述"}
          value={description}
          onInput={(event) => {
            setDescription(event.target.value);
          }}
        />
        <FormControl variant="standard" fullWidth margin="normal">
          <InputLabel>{"校验要求"}</InputLabel>
          <Select
            value={inspectable}
            onChange={(event) => {
              setInspectable(event.target.value);
            }}
          >
            {inspectableOptions.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            submitEdition();
          }}
        >
          {"修改"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
