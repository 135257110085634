import { Badge, Book, Inbox, People, Security, Web } from "@mui/icons-material";
import LoginView from "../../views/LoginView";
import { redirect } from "react-router-dom";
import UserManagerView from "../../views/UserManagerView";
import RoleManagerView from "../../views/RoleManagerView";
import RouteManagerView from "../../views/RouteManagerView";
import PermissionManagerView from "../../views/PermissionManagerView";
import ProjectManagerView from "../../views/ProjectManagerView";
import LogManagerView from "../../views/LogManagerView";

export const unauthRoutes = [
  { path: "/", element: <LoginView />, title: "登录" },
  {
    path: "*",
    loader: () => {
      return redirect("/");
    },
  },
];

export function parseRoutesList(routesList) {
  const parsedRoutesList = [];
  routesList.forEach((route) => {
    let element = null;
    let icon = null;
    switch (route.path) {
      case "/user-manager":
        element = <UserManagerView />;
        icon = <People />;
        break;
      case "/role-manager":
        element = <RoleManagerView />;
        icon = <Badge />;
        break;
      case "/route-manager":
        element = <RouteManagerView />;
        icon = <Web />;
        break;
      case "/permission-manager":
        element = <PermissionManagerView />;
        icon = <Security />;
        break;
      case "/project-manager":
        element = <ProjectManagerView />;
        icon = <Inbox />;
        break;
      case "/log-manager":
        element = <LogManagerView />;
        icon = <Book />;
        break;
      default:
        break;
    }
    if (element) {
      parsedRoutesList.push({
        path: route.path,
        element,
        title: route.title,
        icon,
      });
    }
  });
  parsedRoutesList.push({
    path: "*",
    loader: () => {
      return redirect(routesList[0].path);
    },
  });
  return parsedRoutesList;
}
