import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useState } from "react";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";

export default function ChangePasswordDialog({
  dialogState,
  userInfo,
  logout,
  finishEdition,
}) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [passwordValidator] = useState(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/
  );

  const [password, setPassword] = useState({ value: "" });
  const [passwordChecker, setPasswordChecker] = useState({ value: "" });

  const fillPassword = (value) => {
    if (value === "") {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage: "密码不能为空",
      });
    } else if (!value.match(passwordValidator)) {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage:
          "密码应当至少包含一个大写字母、一个小写字母、一个数字和一个特殊符号，并且长度在8-20位",
      });
    } else {
      setPassword({
        ...password,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const fillPasswordChecker = (value) => {
    if (value === "") {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage: "重复密码不能为空",
      });
    } else if (value !== password.value) {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage: "与密码不同",
      });
    } else if (!value.match(passwordValidator)) {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: true,
        errorMessage:
          "密码应当至少包含一个大写字母、一个小写字母、一个数字和一个特殊符号，并且长度在8-20位",
      });
    } else {
      setPasswordChecker({
        ...passwordChecker,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const changePasswordVisibility = () => {
    setPassword({
      ...password,
      visiable: !password.visiable,
    });
    setPasswordChecker({
      ...passwordChecker,
      visiable: !passwordChecker.visiable,
    });
  };

  const submitEdition = () => {
    const putObj = {
      putUserEntList: [
        {
          id: userInfo.userId,
          password: password.value,
        },
      ],
    };
    _axios.put("/rbac/user", putObj).then((resp) => {
      if (resp.data.code === "00") {
        enqueueSnackbar("密码修改成功，即将返回登陆页面～", {
          variant: "success",
        });
        setTimeout(() => {
          logout();
        }, 3000);
      } else {
        enqueueSnackbar("密码修改失败，请检查输入内容！", { variant: "error" });
      }
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={dialogState}
      onClose={() => {
        finishEdition();
      }}
    >
      <DialogTitle>
        修改密码
        <IconButton
          color="inherit"
          onClick={() => {
            finishEdition();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Icon.Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <FormControl
          variant="standard"
          margin="normal"
          fullWidth
          error={password.errorFlag}
        >
          <InputLabel>新密码</InputLabel>
          <Input
            type={password.visiable ? "text" : "password"}
            value={password.value}
            onInput={(event) => {
              fillPassword(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    changePasswordVisibility();
                  }}
                >
                  {password.visiable ? (
                    <Icon.VisibilityOff />
                  ) : (
                    <Icon.Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{password.errorMessage}</FormHelperText>
        </FormControl>

        <FormControl
          variant="standard"
          margin="normal"
          fullWidth
          error={passwordChecker.errorFlag}
        >
          <InputLabel>重复新密码</InputLabel>
          <Input
            type={passwordChecker.visiable ? "text" : "password"}
            value={passwordChecker.value}
            onInput={(event) => {
              fillPasswordChecker(event.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    changePasswordVisibility();
                  }}
                >
                  {passwordChecker.visiable ? (
                    <Icon.VisibilityOff />
                  ) : (
                    <Icon.Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText>{passwordChecker.errorMessage}</FormHelperText>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={
            password.name === "" ||
            passwordChecker.name === "" ||
            password.errorFlag ||
            passwordChecker.errorFlag
          }
          onClick={() => {
            submitEdition();
          }}
        >
          修改
        </Button>
      </DialogActions>
    </Dialog>
  );
}
