import {
  ChevronLeft,
  ChevronRight,
  Close,
  Security,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { blue, green, orange } from "@mui/material/colors";
import { infinite } from "../../plugin/utils";

export default function EditPermissionDialog({ open, route, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [permissionList, setPermissionList] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedPermissionOptions, setSelectedPermissionOptions] = useState(
    []
  );
  const [routePermissionList, setRoutePermissionList] = useState([]);
  const [selectedRoutePermissionOptions, setSelectedRoutePermissionOptions] =
    useState([]);

  const permissionListInit = () => {
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      projectIdList: selectedProject.id,
    };
    _axios.get("/rbac/permission", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setPermissionList(resp.data.data.permissionList);
      } else {
        enqueueSnackbar("获取权限列表失败！", { variant: "error" });
      }
    });
  };

  const routePermissionListInit = () => {
    if (route.id) {
      const routePermissionGetParams = {
        pageSize: infinite,
        pageNum: 1,
        routeIdList: route.id,
      };
      _axios
        .get("/rbac/route-permission", { params: routePermissionGetParams })
        .then((routePermissionResp) => {
          if (routePermissionResp.data.code === "00") {
            if (routePermissionResp.data.data.total <= 0) {
              setRoutePermissionList([]);
            } else {
              let permissionIdList = [];
              routePermissionResp.data.data.routePermissionList.forEach(
                (routePermission) => {
                  permissionIdList.push(routePermission.permissionId);
                }
              );
              const permissionGetParams = {
                pageSize: infinite,
                pageNum: 1,
                permissionIdList: permissionIdList.toString(),
              };
              _axios
                .get("/rbac/permission", { params: permissionGetParams })
                .then((resp) => {
                  if (resp.data.code === "00") {
                    setRoutePermissionList(resp.data.data.permissionList);
                  } else {
                    enqueueSnackbar("获取当前菜单的权限列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前菜单的权限列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setRoutePermissionList([]);
    }
  };

  const permissionOptionsInit = () => {
    setPermissionOptions(
      permissionList.filter((option) => {
        return (
          routePermissionList.findIndex((subOption) => {
            return subOption.id === option.id;
          }) === -1
        );
      })
    );
  };

  const selectAllPermissionOptions = () => {
    if (selectedPermissionOptions.length !== permissionOptions.length) {
      setSelectedPermissionOptions(permissionOptions);
    } else {
      setSelectedPermissionOptions([]);
    }
  };

  const selectPermissionOption = (permission) => {
    let tmpSelectedPermissionOptions = selectedPermissionOptions;
    const permissionIndexInSelectedPermissionOptions =
      selectedPermissionOptions.findIndex((option) => {
        return option.id === permission.id;
      });
    if (permissionIndexInSelectedPermissionOptions !== -1) {
      tmpSelectedPermissionOptions.splice(
        permissionIndexInSelectedPermissionOptions,
        1
      );
    } else {
      tmpSelectedPermissionOptions.push(permission);
    }
    setSelectedPermissionOptions([...tmpSelectedPermissionOptions]);
  };

  const addRoutePermission = () => {
    let routePermissionRelList = [];
    selectedPermissionOptions.forEach((option) => {
      routePermissionRelList.push({
        routeId: route.id,
        permissionId: option.id,
      });
    });
    const postObj = {
      postRoutePermissionRelList: routePermissionRelList,
    };
    _axios.post("/rbac/route-permission", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setRefreshListFlag(refreshListFlag + 1);
        enqueueSnackbar("向当前菜单添加权限成功！", { variant: "success" });
      } else {
        enqueueSnackbar("向当前菜单添加权限失败！", { variant: "error" });
      }
    });
  };

  const deleteRoutePermission = () => {
    let permissionIdList = [];
    selectedRoutePermissionOptions.forEach((option) => {
      permissionIdList.push(option.id);
    });
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      routeIdList: route.id,
      permissionIdList: permissionIdList.toString(),
    };
    _axios
      .get("/rbac/route-permission", { params: getParams })
      .then((getResp) => {
        if (getResp.data.code === "00") {
          let routePermissionIdList = [];
          getResp.data.data.routePermissionList.forEach((routePermission) => {
            routePermissionIdList.push(routePermission.id);
          });
          const deleteParams = {
            routePermissionIdList: routePermissionIdList.toString(),
          };
          _axios
            .delete("/rbac/route-permission", { params: deleteParams })
            .then((deleteResp) => {
              if (deleteResp.data.code === "00") {
                setRefreshListFlag(refreshListFlag + 1);
                enqueueSnackbar("移除当前菜单的权限成功！", {
                  variant: "success",
                });
              } else {
                enqueueSnackbar("移除当前菜单的权限失败！", {
                  variant: "error",
                });
              }
            });
        } else {
          enqueueSnackbar("移除当前菜单的权限失败！", { variant: "error" });
        }
      });
  };

  const selectAllRoutePermissionOptions = () => {
    if (selectedRoutePermissionOptions.length !== routePermissionList.length) {
      setSelectedRoutePermissionOptions(routePermissionList);
    } else {
      setSelectedRoutePermissionOptions([]);
    }
  };

  const selectRoutePermissionOption = (routePermission) => {
    let tmpSelectedRoutePermissionOptions = selectedRoutePermissionOptions;
    const routePermissionIndexInSelectedRoutePermissionOptions =
      selectedRoutePermissionOptions.findIndex((option) => {
        return option.id === routePermission.id;
      });
    if (routePermissionIndexInSelectedRoutePermissionOptions !== -1) {
      tmpSelectedRoutePermissionOptions.splice(
        routePermissionIndexInSelectedRoutePermissionOptions,
        1
      );
    } else {
      tmpSelectedRoutePermissionOptions.push(routePermission);
    }
    setSelectedRoutePermissionOptions([...tmpSelectedRoutePermissionOptions]);
  };

  useEffect(() => {
    permissionListInit();
  }, [selectedProject]);

  useEffect(() => {
    routePermissionListInit();
    setSelectedPermissionOptions([]);
    setSelectedRoutePermissionOptions([]);
  }, [route, refreshListFlag]);

  useEffect(() => {
    permissionOptionsInit();
  }, [permissionList, routePermissionList]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        配置权限
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} columns={11}>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px" }}
                  >
                    <Security fontSize="inherit" />
                  </Avatar>
                }
                title={"未选择权限"}
                subheader={
                  selectedPermissionOptions.length +
                  "/" +
                  permissionOptions.length
                }
                action={
                  <Checkbox
                    disabled={permissionOptions.length <= 0}
                    checked={
                      permissionOptions.length > 0 &&
                      selectedPermissionOptions.length ===
                        permissionOptions.length
                    }
                    indeterminate={
                      permissionOptions.length > 0 &&
                      selectedPermissionOptions.length > 0 &&
                      selectedPermissionOptions.length !==
                        permissionOptions.length
                    }
                    onClick={() => {
                      selectAllPermissionOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {permissionOptions.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={
                          option.inspectable === 0
                            ? {
                                width: "30px",
                                height: "30px",
                                bgcolor: orange[900],
                              }
                            : {
                                width: "30px",
                                height: "30px",
                                bgcolor: green[900],
                              }
                        }
                      >
                        <Security fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.method + ": " + option.uri}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedPermissionOptions.findIndex(
                            (selectedPermissionOption) => {
                              return option.id === selectedPermissionOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectPermissionOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="small"
              disabled={selectedPermissionOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                addRoutePermission();
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={selectedRoutePermissionOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                deleteRoutePermission();
              }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px", bgcolor: blue["700"] }}
                  >
                    <Security fontSize="inherit" />
                  </Avatar>
                }
                title={"已选择权限"}
                subheader={
                  selectedRoutePermissionOptions.length +
                  "/" +
                  routePermissionList.length
                }
                action={
                  <Checkbox
                    disabled={routePermissionList.length <= 0}
                    checked={
                      routePermissionList.length > 0 &&
                      selectedRoutePermissionOptions.length ===
                        routePermissionList.length
                    }
                    indeterminate={
                      routePermissionList.length > 0 &&
                      selectedRoutePermissionOptions.length > 0 &&
                      selectedRoutePermissionOptions.length !==
                        routePermissionList.length
                    }
                    onClick={() => {
                      selectAllRoutePermissionOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {routePermissionList.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={
                          option.inspectable === 0
                            ? {
                                width: "30px",
                                height: "30px",
                                bgcolor: orange[900],
                              }
                            : {
                                width: "30px",
                                height: "30px",
                                bgcolor: green[900],
                              }
                        }
                      >
                        <Security fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.method + ": " + option.uri}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedRoutePermissionOptions.findIndex(
                            (selectedRoutePermissionOption) => {
                              return (
                                option.id === selectedRoutePermissionOption.id
                              );
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectRoutePermissionOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
