import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as Icon from "@mui/icons-material";
import { setToken } from "../../plugin/store/slices/tokenSlice";
import _axios from "../../plugin/axios";
import { v4 } from "uuid";
import { useSnackbar } from "notistack";
import { setUserInfo } from "../../plugin/store/slices/userInfoSlice";
import { endOfToday } from "date-fns";

export default function LoginCard() {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const dispatch = useDispatch();

  // state变量区
  const [phone, setPhone] = useState({
    value: "",
  });
  const [password, setPassword] = useState({
    value: "",
    visiable: false,
  });
  const [userOptions, setUserOptions] = useState([]);
  const [showUserOptionsFlag, setShowUserOptionsFlag] = useState(false);

  const fillPhone = (value) => {
    setUserOptions([]);
    setShowUserOptionsFlag(false);
    const phoneReg = /^1[2-9]\d{9}$/;
    if (phoneReg.test(value)) {
      setPhone({
        ...phone,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    } else {
      setPhone({
        ...phone,
        value,
        errorFlag: true,
        errorMessage: "手机号码格式错误",
      });
    }
  };

  const fillPassword = (value) => {
    setUserOptions([]);
    setShowUserOptionsFlag(false);
    if (value === "") {
      setPassword({
        ...password,
        value,
        errorFlag: true,
        errorMessage: "密码不能为空",
      });
    } else {
      setPassword({
        ...password,
        value,
        errorFlag: false,
        errorMessage: "",
      });
    }
  };

  const changePasswordVisibility = () => {
    setPassword({
      ...password,
      visiable: !password.visiable,
    });
  };

  // 重置方法
  const reset = () => {
    setPhone({
      value: "",
      errorMessage: "",
    });
    setPassword({
      value: "",
      visiable: false,
    });
    setUserOptions([]);
    setShowUserOptionsFlag(false);
  };

  const getLoginOptions = () => {
    let getParams = {
      phone: phone.value,
      projectId: 4,
    };
    _axios
      .get("/rbac/auth/login-option", { params: getParams })
      .then((resp) => {
        if (resp.data.code === "00") {
          setUserOptions(resp.data.data);
          if (resp.data.data.length !== 1) {
            setShowUserOptionsFlag(true);
          } else {
            login(resp.data.data[0].userId, resp.data.data[0].username);
          }
        } else {
          enqueueSnackbar("获取登陆选项失败，请检查登陆信息！", {
            variant: "error",
          });
        }
      });
  };

  // 登录方法
  const login = (userId, username) => {
    const postObj = {
      userId: userId,
      password: password.value,
      loginMethod: 0,
      expiration: endOfToday(),
    };
    _axios.post("/rbac/auth/do-authorization", postObj).then((resp) => {
      if (resp.data.code === "00") {
        dispatch(setToken(resp.data.data));
        dispatch(setUserInfo({ userId, username }));
      } else {
        enqueueSnackbar("登陆失败，请检查登陆信息！", { variant: "error" });
      }
    });
  };

  return (
    <Box>
      <Card elevation={4} sx={{ width: 360 }}>
        <CardMedia
          component="img"
          image={require("../../assets/loginIcon.png")}
          sx={{
            mx: "auto",
            mt: 4,
            width: 240,
          }}
        />
        <CardContent>
          <TextField
            label="手机号码"
            value={phone.value}
            variant="standard"
            margin="normal"
            fullWidth
            error={phone.errorFlag}
            helperText={phone.errorMessage}
            onInput={(event) => {
              fillPhone(event.target.value);
            }}
          />

          <FormControl
            variant="standard"
            margin="normal"
            fullWidth
            error={password.errorFlag}
          >
            <InputLabel>密码</InputLabel>
            <Input
              type={password.visiable ? "text" : "password"}
              value={password.value}
              onInput={(event) => {
                fillPassword(event.target.value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      changePasswordVisibility();
                    }}
                  >
                    {password.visiable ? (
                      <Icon.VisibilityOff />
                    ) : (
                      <Icon.Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{password.errorMessage}</FormHelperText>
          </FormControl>
        </CardContent>

        <CardActions sx={{ justifyContent: "right" }}>
          <Button
            disabled={phone.value === "" && password.value === ""}
            color="inherit"
            onClick={() => {
              reset();
            }}
          >
            重置
          </Button>
          <Button
            disabled={
              phone.value === "" ||
              password.value === "" ||
              phone.errorFlag ||
              password.errorFlag
            }
            onClick={() => {
              getLoginOptions();
            }}
          >
            登录
          </Button>
        </CardActions>

        {showUserOptionsFlag && userOptions.length > 1 && (
          <CardContent key={v4()}>
            <Typography variant="body2" mb={1}>
              该手机号下存在多个用户，请选择本次登录的用户
            </Typography>
            {userOptions.map((user, index) => (
              <Button
                key={index}
                size="small"
                onClick={() => {
                  login(user.userId, user.username);
                }}
              >
                {user.username}
              </Button>
            ))}
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
