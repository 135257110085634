import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { Add, Delete, Search, Security, Web } from "@mui/icons-material";
import { useSelector } from "react-redux";

export default function RouteCardServerList({
  refreshListFlag,
  addRoute,
  editRoute,
  editPermission,
  deleteRoute,
}) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [keyWord, setKeyWord] = useState("");
  const [routeList, setRouteList] = useState([]);
  const [pages, setPages] = useState(1);
  const [total, setTotal] = useState(0);
  const [pagination, setPagination] = useState({ pageSize: 100, pageNum: 1 });
  const [pageSizeOptions] = useState([10, 25, 50, 100]);

  const routeListInit = () => {
    const getParams = {
      pageSize: pagination.pageSize,
      pageNum: pagination.pageNum,
      projectIdList: selectedProject.id,
      keyWord: keyWord,
    };
    _axios.get("/rbac/route", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setRouteList(resp.data.data.routeList);
        setPages(resp.data.data.pages);
        setTotal(resp.data.data.total);
      } else {
        enqueueSnackbar("获取菜单列表失败！", { variant: "error" });
      }
    });
  };

  const changePageSize = (pageSize) => {
    setPagination({
      ...pagination,
      pageSize,
    });
  };

  const changePageNum = (pageNum) => {
    setPagination({
      ...pagination,
      pageNum,
    });
  };

  useEffect(() => {
    routeListInit();
  }, [refreshListFlag, selectedProject, keyWord, pagination]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8} display={"flex"} alignItems={"center"}>
        <Typography>{"每页显示 "}</Typography>
        <Select
          variant="standard"
          value={pagination.pageSize}
          onChange={(event) => {
            changePageSize(event.target.value);
          }}
          sx={{ mx: 1 }}
        >
          {pageSizeOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
        <Typography>{" 条，共 " + total + " 条记录"}</Typography>
        <Divider orientation="vertical" sx={{ mx: 2 }} />
        <Button
          startIcon={<Add />}
          onClick={() => {
            addRoute();
          }}
        >
          {"添加菜单"}
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          variant="standard"
          fullWidth
          placeholder={"关键词查询"}
          InputProps={{
            endAdornment: <Search />,
          }}
          value={keyWord}
          onInput={(event) => {
            setKeyWord(event.target.value);
          }}
        />
      </Grid>
      {routeList.map((route, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <RouteCard
            route={route}
            editRoute={editRoute}
            editPermission={editPermission}
            deleteRoute={deleteRoute}
          />
        </Grid>
      ))}
      {pages > 0 && (
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Pagination
            count={pages}
            page={pagination.pageNum}
            shape="rounded"
            onChange={(event, value) => {
              changePageNum(value);
            }}
          />
        </Grid>
      )}
    </Grid>
  );
}

function RouteCard({ route, editRoute, editPermission, deleteRoute }) {
  return (
    <Card>
      <CardHeader
        avatar={
          <Avatar variant="rounded" sx={{ width: "30px", height: "30px" }}>
            <Web fontSize="inherit" />
          </Avatar>
        }
        title={route.title}
        subheader={route.path}
      ></CardHeader>
      <CardActions>
        <Tooltip arrow placement="top" title="编辑菜单信息">
          <IconButton
            size="small"
            onClick={() => {
              editRoute(route);
            }}
          >
            <Web fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement="top" title="配置权限">
          <IconButton
            size="small"
            onClick={() => {
              editPermission(route);
            }}
          >
            <Security fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Box flex={1}></Box>
        <Tooltip arrow placement="top" title="删除菜单">
          <IconButton
            size="small"
            onClick={() => {
              deleteRoute(route);
            }}
          >
            <Delete fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
}
