import { ChevronLeft, ChevronRight, Close, Web } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { blue, green, orange } from "@mui/material/colors";
import { infinite } from "../../plugin/utils";

export default function EditRouteDialog({ open, role, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [routeList, setRouteList] = useState([]);
  const [routeOptions, setRouteOptions] = useState([]);
  const [selectedRouteOptions, setSelectedRouteOptions] = useState([]);
  const [roleRouteList, setRoleRouteList] = useState([]);
  const [selectedRoleRouteOptions, setSelectedRoleRouteOptions] = useState([]);

  const routeListInit = () => {
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      projectIdList: selectedProject.id,
    };
    _axios.get("/rbac/route", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setRouteList(resp.data.data.routeList);
      } else {
        enqueueSnackbar("获取菜单列表失败！", { variant: "error" });
      }
    });
  };

  const roleRouteListInit = () => {
    if (role.id) {
      const roleRouteGetParams = {
        pageSize: infinite,
        pageNum: 1,
        roleIdList: role.id,
      };
      _axios
        .get("/rbac/role-route", { params: roleRouteGetParams })
        .then((roleRouteResp) => {
          if (roleRouteResp.data.code === "00") {
            if (roleRouteResp.data.data.total <= 0) {
              setRoleRouteList([]);
            } else {
              let routeIdList = [];
              roleRouteResp.data.data.roleRouteList.forEach((roleRoute) => {
                routeIdList.push(roleRoute.routeId);
              });
              const routeGetParams = {
                pageSize: infinite,
                pageNum: 1,
                routeIdList: routeIdList.toString(),
              };
              _axios
                .get("/rbac/route", { params: routeGetParams })
                .then((resp) => {
                  if (resp.data.code === "00") {
                    setRoleRouteList(resp.data.data.routeList);
                  } else {
                    enqueueSnackbar("获取当前角色的菜单列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前角色的菜单列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setRoleRouteList([]);
    }
  };

  const routeOptionsInit = () => {
    setRouteOptions(
      routeList.filter((option) => {
        return (
          roleRouteList.findIndex((subOption) => {
            return subOption.id === option.id;
          }) === -1
        );
      })
    );
  };

  const selectAllRouteOptions = () => {
    if (selectedRouteOptions.length !== routeOptions.length) {
      setSelectedRouteOptions(routeOptions);
    } else {
      setSelectedRouteOptions([]);
    }
  };

  const selectRouteOption = (route) => {
    let tmpSelectedRouteOptions = selectedRouteOptions;
    const routeIndexInSelectedRouteOptions = selectedRouteOptions.findIndex(
      (option) => {
        return option.id === route.id;
      }
    );
    if (routeIndexInSelectedRouteOptions !== -1) {
      tmpSelectedRouteOptions.splice(routeIndexInSelectedRouteOptions, 1);
    } else {
      tmpSelectedRouteOptions.push(route);
    }
    setSelectedRouteOptions([...tmpSelectedRouteOptions]);
  };

  const addRoleRoute = () => {
    let roleRouteRelList = [];
    selectedRouteOptions.forEach((option) => {
      roleRouteRelList.push({
        roleId: role.id,
        routeId: option.id,
      });
    });
    const postObj = {
      postRoleRouteRelList: roleRouteRelList,
    };
    _axios.post("/rbac/role-route", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setRefreshListFlag(refreshListFlag + 1);
        enqueueSnackbar("向当前角色添加菜单成功！", { variant: "success" });
      } else {
        enqueueSnackbar("向当前角色添加菜单失败！", { variant: "error" });
      }
    });
  };

  const deleteRoleRoute = () => {
    let routeIdList = [];
    selectedRoleRouteOptions.forEach((option) => {
      routeIdList.push(option.id);
    });
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      roleIdList: role.id,
      routeIdList: routeIdList.toString(),
    };
    _axios.get("/rbac/role-route", { params: getParams }).then((getResp) => {
      if (getResp.data.code === "00") {
        let roleRouteIdList = [];
        getResp.data.data.roleRouteList.forEach((roleRoute) => {
          roleRouteIdList.push(roleRoute.id);
        });
        const deleteParams = {
          roleRouteIdList: roleRouteIdList.toString(),
        };
        _axios
          .delete("/rbac/role-route", { params: deleteParams })
          .then((deleteResp) => {
            if (deleteResp.data.code === "00") {
              setRefreshListFlag(refreshListFlag + 1);
              enqueueSnackbar("移除当前角色的菜单成功！", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("移除当前角色的菜单失败！", {
                variant: "error",
              });
            }
          });
      } else {
        enqueueSnackbar("移除当前角色的菜单失败！", { variant: "error" });
      }
    });
  };

  const selectAllRoleRouteOptions = () => {
    if (selectedRoleRouteOptions.length !== roleRouteList.length) {
      setSelectedRoleRouteOptions(roleRouteList);
    } else {
      setSelectedRoleRouteOptions([]);
    }
  };

  const selectRoleRouteOption = (roleRoute) => {
    let tmpSelectedRoleRouteOptions = selectedRoleRouteOptions;
    const roleRouteIndexInSelectedRoleRouteOptions =
      selectedRoleRouteOptions.findIndex((option) => {
        return option.id === roleRoute.id;
      });
    if (roleRouteIndexInSelectedRoleRouteOptions !== -1) {
      tmpSelectedRoleRouteOptions.splice(
        roleRouteIndexInSelectedRoleRouteOptions,
        1
      );
    } else {
      tmpSelectedRoleRouteOptions.push(roleRoute);
    }
    setSelectedRoleRouteOptions([...tmpSelectedRoleRouteOptions]);
  };

  useEffect(() => {
    routeListInit();
  }, [selectedProject]);

  useEffect(() => {
    roleRouteListInit();
    setSelectedRouteOptions([]);
    setSelectedRoleRouteOptions([]);
  }, [role, refreshListFlag]);

  useEffect(() => {
    routeOptionsInit();
  }, [routeList, roleRouteList]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        配置菜单
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} columns={11}>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px" }}
                  >
                    <Web fontSize="inherit" />
                  </Avatar>
                }
                title={"未选择菜单"}
                subheader={
                  selectedRouteOptions.length + "/" + routeOptions.length
                }
                action={
                  <Checkbox
                    disabled={routeOptions.length <= 0}
                    checked={
                      routeOptions.length > 0 &&
                      selectedRouteOptions.length === routeOptions.length
                    }
                    indeterminate={
                      routeOptions.length > 0 &&
                      selectedRouteOptions.length > 0 &&
                      selectedRouteOptions.length !== routeOptions.length
                    }
                    onClick={() => {
                      selectAllRouteOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {routeOptions.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar variant="rounded">
                        <Web fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.title}
                      secondary={option.path}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedRouteOptions.findIndex(
                            (selectedRouteOption) => {
                              return option.id === selectedRouteOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectRouteOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="small"
              disabled={selectedRouteOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                addRoleRoute();
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={selectedRoleRouteOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                deleteRoleRoute();
              }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px", bgcolor: blue["700"] }}
                  >
                    <Web fontSize="inherit" />
                  </Avatar>
                }
                title={"已选择菜单"}
                subheader={
                  selectedRoleRouteOptions.length + "/" + roleRouteList.length
                }
                action={
                  <Checkbox
                    disabled={roleRouteList.length <= 0}
                    checked={
                      roleRouteList.length > 0 &&
                      selectedRoleRouteOptions.length === roleRouteList.length
                    }
                    indeterminate={
                      roleRouteList.length > 0 &&
                      selectedRoleRouteOptions.length > 0 &&
                      selectedRoleRouteOptions.length !== roleRouteList.length
                    }
                    onClick={() => {
                      selectAllRoleRouteOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {roleRouteList.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar variant="rounded">
                        <Web fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.title}
                      secondary={option.path}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedRoleRouteOptions.findIndex(
                            (selectedRoleRouteOption) => {
                              return option.id === selectedRoleRouteOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectRoleRouteOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
