import { useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import RouteCardServerList from "../components/routeManager/RouteCardServerList";
import AddRouteDialog from "../components/routeManager/AddRouteDialog";
import EditRouteDialog from "../components/routeManager/EditRouteDialog";
import DeleteRouteDialog from "../components/routeManager/DeleteRouteDialog";
import EditPermissionDialog from "../components/routeManager/EditPermissionDialog";

export default function RouteManagerView() {
  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [route, setRoute] = useState({});
  const [addRouteFlag, setAddRouteFlag] = useState(false);
  const [editRouteFlag, setEditRouteFlag] = useState(false);
  const [editPermissionFlag, setEditPermissionFlag] = useState(false);
  const [deleteRouteFlag, setDeleteRouteFlag] = useState(false);

  const addRoute = () => {
    setAddRouteFlag(true);
  };

  const finishRouteAddition = () => {
    setAddRouteFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
  };

  const editRoute = (route) => {
    setRoute(route);
    setEditRouteFlag(true);
  };

  const finishRouteEdition = () => {
    setEditRouteFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRoute({});
  };

  const editPermission = (route) => {
    setRoute(route);
    setEditPermissionFlag(true);
  };

  const finishPermissionEdition = () => {
    setEditPermissionFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRoute({});
  };

  const deleteRoute = (route) => {
    setRoute(route);
    setDeleteRouteFlag(true);
  };

  const finishRouteDeletion = () => {
    setDeleteRouteFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRoute({});
  };

  return (
    <AppLayout>
      <RouteCardServerList
        refreshListFlag={refreshListFlag}
        addRoute={addRoute}
        editRoute={editRoute}
        editPermission={editPermission}
        deleteRoute={deleteRoute}
      />
      <AddRouteDialog open={addRouteFlag} closeDialog={finishRouteAddition} />
      <EditRouteDialog
        open={editRouteFlag}
        route={route}
        closeDialog={finishRouteEdition}
      />
      <EditPermissionDialog
        open={editPermissionFlag}
        route={route}
        closeDialog={finishPermissionEdition}
      />
      <DeleteRouteDialog
        open={deleteRouteFlag}
        route={route}
        closeDialog={finishRouteDeletion}
      />
    </AppLayout>
  );
}
