import { Badge, ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { blue } from "@mui/material/colors";
import { infinite } from "../../plugin/utils";

export default function EditRoleDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const selectedProject = useSelector((state) => state.selectedProject);

  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRoleOptions, setSelectedRoleOptions] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [selectedUserRoleOptions, setSelectedUserRoleOptions] = useState([]);

  const roleListInit = () => {
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      projectIdList: selectedProject.id,
    };
    _axios.get("/rbac/role", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setRoleList(resp.data.data.roleList);
      } else {
        enqueueSnackbar("获取角色列表失败！", { variant: "error" });
      }
    });
  };

  const userRoleListInit = () => {
    if (user.id) {
      const userRoleGetParams = {
        pageSize: infinite,
        pageNum: 1,
        userIdList: user.id,
      };
      _axios
        .get("/rbac/user-role", { params: userRoleGetParams })
        .then((userRoleResp) => {
          if (userRoleResp.data.code === "00") {
            if (userRoleResp.data.data.total <= 0) {
              setUserRoleList([]);
            } else {
              let roleIdList = [];
              userRoleResp.data.data.userRoleList.forEach((userRole) => {
                roleIdList.push(userRole.roleId);
              });
              const roleGetParams = {
                pageSize: infinite,
                pageNum: 1,
                roleIdList: roleIdList.toString(),
                projectIdList: selectedProject.id,
              };
              _axios
                .get("/rbac/role", { params: roleGetParams })
                .then((resp) => {
                  if (resp.data.code === "00") {
                    setUserRoleList(resp.data.data.roleList);
                  } else {
                    enqueueSnackbar("获取当前用户的角色列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前用户的角色列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setUserRoleList([]);
    }
  };

  const roleOptionsInit = () => {
    setRoleOptions(
      roleList.filter((option) => {
        return (
          userRoleList.findIndex((subOption) => {
            return subOption.id === option.id;
          }) === -1
        );
      })
    );
  };

  const selectAllRoleOptions = () => {
    if (selectedRoleOptions.length !== roleOptions.length) {
      setSelectedRoleOptions(roleOptions);
    } else {
      setSelectedRoleOptions([]);
    }
  };

  const selectRoleOption = (role) => {
    let tmpSelectedRoleOptions = selectedRoleOptions;
    const roleIndexInSelectedRoleOptions = selectedRoleOptions.findIndex(
      (option) => {
        return option.id === role.id;
      }
    );
    if (roleIndexInSelectedRoleOptions !== -1) {
      tmpSelectedRoleOptions.splice(roleIndexInSelectedRoleOptions, 1);
    } else {
      tmpSelectedRoleOptions.push(role);
    }
    setSelectedRoleOptions([...tmpSelectedRoleOptions]);
  };

  const addUserRole = () => {
    let userRoleRelList = [];
    selectedRoleOptions.forEach((option) => {
      userRoleRelList.push({
        userId: user.id,
        roleId: option.id,
      });
    });
    const postObj = {
      postUserRoleRelList: userRoleRelList,
    };
    _axios.post("/rbac/user-role", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setRefreshListFlag(refreshListFlag + 1);
        enqueueSnackbar("向当前用户添加角色成功！", { variant: "success" });
      } else {
        enqueueSnackbar("向当前用户添加角色失败！", { variant: "error" });
      }
    });
  };

  const deleteUserRole = () => {
    let roleIdList = [];
    selectedUserRoleOptions.forEach((option) => {
      roleIdList.push(option.id);
    });
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      userIdList: user.id,
      roleIdList: roleIdList.toString(),
    };
    _axios.get("/rbac/user-role", { params: getParams }).then((getResp) => {
      if (getResp.data.code === "00") {
        let userRoleIdList = [];
        getResp.data.data.userRoleList.forEach((userRole) => {
          userRoleIdList.push(userRole.id);
        });
        const deleteParams = {
          userRoleIdList: userRoleIdList.toString(),
        };
        _axios
          .delete("/rbac/user-role", { params: deleteParams })
          .then((deleteResp) => {
            if (deleteResp.data.code === "00") {
              setRefreshListFlag(refreshListFlag + 1);
              enqueueSnackbar("移除当前用户的角色成功！", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("移除当前用户的角色失败！", {
                variant: "error",
              });
            }
          });
      } else {
        enqueueSnackbar("移除当前用户的角色失败！", { variant: "error" });
      }
    });
  };

  const selectAllUserRoleOptions = () => {
    if (selectedUserRoleOptions.length !== userRoleList.length) {
      setSelectedUserRoleOptions(userRoleList);
    } else {
      setSelectedUserRoleOptions([]);
    }
  };

  const selectUserRoleOption = (userRole) => {
    let tmpSelectedUserRoleOptions = selectedUserRoleOptions;
    const userRoleIndexInSelectedUserRoleOptions =
      selectedUserRoleOptions.findIndex((option) => {
        return option.id === userRole.id;
      });
    if (userRoleIndexInSelectedUserRoleOptions !== -1) {
      tmpSelectedUserRoleOptions.splice(
        userRoleIndexInSelectedUserRoleOptions,
        1
      );
    } else {
      tmpSelectedUserRoleOptions.push(userRole);
    }
    setSelectedUserRoleOptions([...tmpSelectedUserRoleOptions]);
  };

  useEffect(() => {
    roleListInit();
  }, [selectedProject]);

  useEffect(() => {
    userRoleListInit();
    setSelectedRoleOptions([]);
    setSelectedUserRoleOptions([]);
  }, [user, refreshListFlag]);

  useEffect(() => {
    roleOptionsInit();
  }, [roleList, userRoleList]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        配置角色
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} columns={11}>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar sx={{ width: "30px", height: "30px" }}>
                    <Badge fontSize="inherit" />
                  </Avatar>
                }
                title={"未选择角色"}
                subheader={
                  selectedRoleOptions.length + "/" + roleOptions.length
                }
                action={
                  <Checkbox
                    disabled={roleOptions.length <= 0}
                    checked={
                      roleOptions.length > 0 &&
                      selectedRoleOptions.length === roleOptions.length
                    }
                    indeterminate={
                      roleOptions.length > 0 &&
                      selectedRoleOptions.length > 0 &&
                      selectedRoleOptions.length !== roleOptions.length
                    }
                    onClick={() => {
                      selectAllRoleOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {roleOptions.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: "30px", height: "30px" }}>
                        <Badge fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedRoleOptions.findIndex(
                            (selectedRoleOption) => {
                              return option.id === selectedRoleOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectRoleOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="small"
              disabled={selectedRoleOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                addUserRole();
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={selectedUserRoleOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                deleteUserRole();
              }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ width: "30px", height: "30px", bgcolor: blue["700"] }}
                  >
                    <Badge fontSize="inherit" />
                  </Avatar>
                }
                title={"已选择角色"}
                subheader={
                  selectedUserRoleOptions.length + "/" + userRoleList.length
                }
                action={
                  <Checkbox
                    disabled={userRoleList.length <= 0}
                    checked={
                      userRoleList.length > 0 &&
                      selectedUserRoleOptions.length === userRoleList.length
                    }
                    indeterminate={
                      userRoleList.length > 0 &&
                      selectedUserRoleOptions.length > 0 &&
                      selectedUserRoleOptions.length !== userRoleList.length
                    }
                    onClick={() => {
                      selectAllUserRoleOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {userRoleList.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar sx={{ width: "30px", height: "30px" }}>
                        <Badge fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={option.description}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedUserRoleOptions.findIndex(
                            (selectedUserRoleOption) => {
                              return option.id === selectedUserRoleOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectUserRoleOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
