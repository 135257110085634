import { ChevronLeft, ChevronRight, Close, Inbox } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import _axios from "../../plugin/axios";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { blue } from "@mui/material/colors";
import { infinite } from "../../plugin/utils";

export default function EditProjectDialog({ open, user, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [projectList, setProjectList] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProjectOptions, setSelectedProjectOptions] = useState([]);
  const [userProjectList, setUserProjectList] = useState([]);
  const [selectedUserProjectOptions, setSelectedUserProjectOptions] = useState(
    []
  );

  const projectListInit = () => {
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
    };
    _axios.get("/rbac/project", { params: getParams }).then((resp) => {
      if (resp.data.code === "00") {
        setProjectList(resp.data.data.projectList);
      } else {
        enqueueSnackbar("获取项目列表失败！", { variant: "error" });
      }
    });
  };

  const userProjectListInit = () => {
    if (user.id) {
      const userProjectGetParams = {
        pageSize: infinite,
        pageNum: 1,
        userIdList: user.id,
      };
      _axios
        .get("/rbac/user-project", { params: userProjectGetParams })
        .then((userProjectResp) => {
          if (userProjectResp.data.code === "00") {
            if (userProjectResp.data.data.total <= 0) {
              setUserProjectList([]);
            } else {
              let projectIdList = [];
              userProjectResp.data.data.userProjectList.forEach(
                (userProject) => {
                  projectIdList.push(userProject.projectId);
                }
              );
              const projectGetParams = {
                pageSize: infinite,
                pageNum: 1,
                projectIdList: projectIdList.toString(),
              };
              _axios
                .get("/rbac/project", { params: projectGetParams })
                .then((resp) => {
                  if (resp.data.code === "00") {
                    setUserProjectList(resp.data.data.projectList);
                  } else {
                    enqueueSnackbar("获取当前用户的项目列表失败！", {
                      variant: "error",
                    });
                  }
                });
            }
          } else {
            enqueueSnackbar("获取当前用户的项目列表失败！", {
              variant: "error",
            });
          }
        });
    } else {
      setUserProjectList([]);
    }
  };

  const projectOptionsInit = () => {
    setProjectOptions(
      projectList.filter((option) => {
        return (
          userProjectList.findIndex((subOption) => {
            return subOption.id === option.id;
          }) === -1
        );
      })
    );
  };

  const selectAllProjectOptions = () => {
    if (selectedProjectOptions.length !== projectOptions.length) {
      setSelectedProjectOptions(projectOptions);
    } else {
      setSelectedProjectOptions([]);
    }
  };

  const selectProjectOption = (project) => {
    let tmpSelectedProjectOptions = selectedProjectOptions;
    const projectIndexInSelectedProjectOptions =
      selectedProjectOptions.findIndex((option) => {
        return option.id === project.id;
      });
    if (projectIndexInSelectedProjectOptions !== -1) {
      tmpSelectedProjectOptions.splice(projectIndexInSelectedProjectOptions, 1);
    } else {
      tmpSelectedProjectOptions.push(project);
    }
    setSelectedProjectOptions([...tmpSelectedProjectOptions]);
  };

  const addUserProject = () => {
    let userProjectRelList = [];
    selectedProjectOptions.forEach((option) => {
      userProjectRelList.push({
        userId: user.id,
        projectId: option.id,
      });
    });
    const postObj = {
      postUserProjectRelList: userProjectRelList,
    };
    _axios.post("/rbac/user-project", postObj).then((resp) => {
      if (resp.data.code === "00") {
        setRefreshListFlag(refreshListFlag + 1);
        enqueueSnackbar("向当前用户添加项目成功！", { variant: "success" });
      } else {
        enqueueSnackbar("向当前用户添加项目失败！", { variant: "error" });
      }
    });
  };

  const deleteUserProject = () => {
    let projectIdList = [];
    selectedUserProjectOptions.forEach((option) => {
      projectIdList.push(option.id);
    });
    const getParams = {
      pageSize: infinite,
      pageNum: 1,
      userIdList: user.id,
      projectIdList: projectIdList.toString(),
    };
    _axios.get("/rbac/user-project", { params: getParams }).then((getResp) => {
      if (getResp.data.code === "00") {
        let userProjectIdList = [];
        getResp.data.data.userProjectList.forEach((userProject) => {
          userProjectIdList.push(userProject.id);
        });
        const deleteParams = {
          userProjectIdList: userProjectIdList.toString(),
        };
        _axios
          .delete("/rbac/user-project", { params: deleteParams })
          .then((deleteResp) => {
            if (deleteResp.data.code === "00") {
              setRefreshListFlag(refreshListFlag + 1);
              enqueueSnackbar("移除当前用户的项目成功！", {
                variant: "success",
              });
            } else {
              enqueueSnackbar("移除当前用户的项目失败！", {
                variant: "error",
              });
            }
          });
      } else {
        enqueueSnackbar("移除当前用户的项目失败！", { variant: "error" });
      }
    });
  };

  const selectAllUserProjectOptions = () => {
    if (selectedUserProjectOptions.length !== userProjectList.length) {
      setSelectedUserProjectOptions(userProjectList);
    } else {
      setSelectedUserProjectOptions([]);
    }
  };

  const selectUserProjectOption = (userProject) => {
    let tmpSelectedUserProjectOptions = selectedUserProjectOptions;
    const userProjectIndexInSelectedUserProjectOptions =
      selectedUserProjectOptions.findIndex((option) => {
        return option.id === userProject.id;
      });
    if (userProjectIndexInSelectedUserProjectOptions !== -1) {
      tmpSelectedUserProjectOptions.splice(
        userProjectIndexInSelectedUserProjectOptions,
        1
      );
    } else {
      tmpSelectedUserProjectOptions.push(userProject);
    }
    setSelectedUserProjectOptions([...tmpSelectedUserProjectOptions]);
  };

  useEffect(() => {
    projectListInit();
  }, []);

  useEffect(() => {
    userProjectListInit();
    setSelectedProjectOptions([]);
    setSelectedUserProjectOptions([]);
  }, [user, refreshListFlag]);

  useEffect(() => {
    projectOptionsInit();
  }, [projectList, userProjectList]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        配置项目
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} columns={11}>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px" }}
                  >
                    <Inbox fontSize="inherit" />
                  </Avatar>
                }
                title={"未选择项目"}
                subheader={
                  selectedProjectOptions.length + "/" + projectOptions.length
                }
                action={
                  <Checkbox
                    disabled={projectOptions.length <= 0}
                    checked={
                      projectOptions.length > 0 &&
                      selectedProjectOptions.length === projectOptions.length
                    }
                    indeterminate={
                      projectOptions.length > 0 &&
                      selectedProjectOptions.length > 0 &&
                      selectedProjectOptions.length !== projectOptions.length
                    }
                    onClick={() => {
                      selectAllProjectOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {projectOptions.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{ width: "30px", height: "30px" }}
                      >
                        <Inbox fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={option.baseUrl}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedProjectOptions.findIndex(
                            (selectedProjectOption) => {
                              return option.id === selectedProjectOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectProjectOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Button
              variant="contained"
              size="small"
              disabled={selectedProjectOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                addUserProject();
              }}
            >
              <ChevronRight />
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={selectedUserProjectOptions.length <= 0}
              sx={{ m: 1 }}
              onClick={() => {
                deleteUserProject();
              }}
            >
              <ChevronLeft />
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <CardHeader
                avatar={
                  <Avatar
                    variant="rounded"
                    sx={{ width: "30px", height: "30px", bgcolor: blue["700"] }}
                  >
                    <Inbox fontSize="inherit" />
                  </Avatar>
                }
                title={"已选择项目"}
                subheader={
                  selectedUserProjectOptions.length +
                  "/" +
                  userProjectList.length
                }
                action={
                  <Checkbox
                    disabled={userProjectList.length <= 0}
                    checked={
                      userProjectList.length > 0 &&
                      selectedUserProjectOptions.length ===
                        userProjectList.length
                    }
                    indeterminate={
                      userProjectList.length > 0 &&
                      selectedUserProjectOptions.length > 0 &&
                      selectedUserProjectOptions.length !==
                        userProjectList.length
                    }
                    onClick={() => {
                      selectAllUserProjectOptions();
                    }}
                  />
                }
              />
              <Divider />
              <List sx={{ height: "480px", overflow: "auto" }}>
                {userProjectList.map((option, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        sx={{ width: "30px", height: "30px" }}
                      >
                        <Inbox fontSize="inherit" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={option.name}
                      secondary={option.baseUrl}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        checked={
                          selectedUserProjectOptions.findIndex(
                            (selectedUserProjectOption) => {
                              return option.id === selectedUserProjectOption.id;
                            }
                          ) !== -1
                        }
                        onClick={() => {
                          selectUserProjectOption(option);
                        }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
