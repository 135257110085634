import { useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import ProjectCardServerList from "../components/projectManager/ProjectCardServerList";
import AddProjectDialog from "../components/projectManager/AddProjectDialog";
import EditProjectDialog from "../components/projectManager/EditProjectDialog";
import DeleteProjectDialog from "../components/projectManager/DeleteProjectDialog";

export default function ProjectManagerView() {
  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [project, setProject] = useState({});
  const [addProjectFlag, setAddProjectFlag] = useState(false);
  const [editProjectFlag, setEditProjectFlag] = useState(false);
  const [deleteProjectFlag, setDeleteProjectFlag] = useState(false);

  const addProject = () => {
    setAddProjectFlag(true);
  };

  const finishProjectAddition = () => {
    setAddProjectFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
  };

  const editProject = (project) => {
    setProject(project);
    setEditProjectFlag(true);
  };

  const finishProjectEdition = () => {
    setEditProjectFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setProject({});
  };

  const deleteProject = (project) => {
    setProject(project);
    setDeleteProjectFlag(true);
  };

  const finishProjectDeletion = () => {
    setDeleteProjectFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setProject({});
  };

  return (
    <AppLayout>
      <ProjectCardServerList
        refreshListFlag={refreshListFlag}
        addProject={addProject}
        editProject={editProject}
        deleteProject={deleteProject}
      />
      <AddProjectDialog
        open={addProjectFlag}
        closeDialog={finishProjectAddition}
      />
      <EditProjectDialog
        open={editProjectFlag}
        project={project}
        closeDialog={finishProjectEdition}
      />
      <DeleteProjectDialog
        open={deleteProjectFlag}
        project={project}
        closeDialog={finishProjectDeletion}
      />
    </AppLayout>
  );
}
