import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoadingView from "./views/LoadingView";
import _axios from "./plugin/axios";
import { setRoutesList } from "./plugin/store/slices/routesListSlice";
import { useSnackbar } from "notistack";
import { parseRoutesList, unauthRoutes } from "./plugin/routes";

export default function App() {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // redux变量区
  const token = useSelector((state) => state.token);
  const userInfo = useSelector((state) => state.userInfo);
  const routesList = useSelector((state) => state.routesList);
  const dispatch = useDispatch();

  // 请求后端填充前端路由
  const routesListInit = () => {
    if (userInfo.userId !== 0 && token.value !== "") {
      const userRoleGetParams = {
        pageSize: 100,
        pageNum: 1,
        userIdList: userInfo.userId,
      };
      _axios
        .get("/rbac/user-role", { params: userRoleGetParams })
        .then((userRoleResp) => {
          if (userRoleResp.data.code === "00") {
            let roleIdList = [];
            userRoleResp.data.data.userRoleList.forEach((userRole) => {
              roleIdList.push(userRole.roleId);
            });
            const roleRouteGetParams = {
              pageSize: 100,
              pageNum: 1,
              roleIdList: roleIdList.toString(),
            };
            _axios
              .get("/rbac/role-route", { params: roleRouteGetParams })
              .then((roleRouteResp) => {
                if (roleRouteResp.data.code === "00") {
                  let routeIdList = [];
                  roleRouteResp.data.data.roleRouteList.forEach((roleRoute) => {
                    routeIdList.push(roleRoute.routeId);
                  });
                  const routeGetParams = {
                    pageSize: 100,
                    pageNum: 1,
                    projectIdList: 4,
                    routeIdList: routeIdList.toString(),
                  };
                  _axios
                    .get("/rbac/route", { params: routeGetParams })
                    .then((routeResp) => {
                      if (routeResp.data.code === "00") {
                        dispatch(
                          setRoutesList(
                            parseRoutesList(routeResp.data.data.routeList)
                          )
                        );
                      } else {
                        enqueueSnackbar("获取路由信息失败！", {
                          variant: "error",
                        });
                      }
                    });
                } else {
                  enqueueSnackbar("获取路由信息失败！", {
                    variant: "error",
                  });
                }
              });
          } else {
            enqueueSnackbar("获取路由信息失败！", { variant: "error" });
          }
        });
    } else {
      dispatch(setRoutesList(unauthRoutes));
    }
  };

  // 创建前端路由
  const router = routesList.value[0]
    ? createBrowserRouter(routesList.value)
    : createBrowserRouter([
        { path: "*", element: <LoadingView />, title: "加载中……" },
      ]);

  // 监听redux变量token的变化情况，token改变是请求后端获取前端路由
  useEffect(() => {
    routesListInit();
  }, [token]);

  return <RouterProvider router={router} />;
}
