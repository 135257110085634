import { useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import RoleCardServerList from "../components/roleManager/RoleCardServerList";
import AddRoleDialog from "../components/roleManager/AddRoleDialog";
import EditRoleDialog from "../components/roleManager/EditRoleDialog";
import DeleteRoleDialog from "../components/roleManager/DeleteRoleDialog";
import EditRouteDialog from "../components/roleManager/EditRouteDialog";
import EditPermissionDialog from "../components/roleManager/EditPermissionDialog";

export default function RoleManagerView() {
  // state变量区
  const [refreshListFlag, setRefreshListFlag] = useState(0);
  const [role, setRole] = useState({});
  const [addRoleFlag, setAddRoleFlag] = useState(false);
  const [editRoleFlag, setEditRoleFlag] = useState(false);
  const [editRouteFlag, setEditRouteFlag] = useState(false);
  const [editPermissionFlag, setEditPermissionFlag] = useState(false);
  const [deleteRoleFlag, setDeleteRoleFlag] = useState(false);

  const addRole = () => {
    setAddRoleFlag(true);
  };

  const finishRoleAddition = () => {
    setAddRoleFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
  };

  const editRole = (role) => {
    setRole(role);
    setEditRoleFlag(true);
  };

  const finishRoleEdition = () => {
    setEditRoleFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRole({});
  };

  const editRoute = (role) => {
    setRole(role);
    setEditRouteFlag(true);
  };

  const finishRouteEdition = () => {
    setEditRouteFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRole({});
  };

  const editPermission = (role) => {
    setRole(role);
    setEditPermissionFlag(true);
  };

  const finishPermissionEdition = () => {
    setEditPermissionFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRole({});
  };

  const deleteRole = (role) => {
    setRole(role);
    setDeleteRoleFlag(true);
  };

  const finishRoleDeletion = () => {
    setDeleteRoleFlag(false);
    setRefreshListFlag(refreshListFlag + 1);
    setRole({});
  };

  return (
    <AppLayout>
      <RoleCardServerList
        refreshListFlag={refreshListFlag}
        addRole={addRole}
        editRole={editRole}
        editRoute={editRoute}
        editPermission={editPermission}
        deleteRole={deleteRole}
      />
      <AddRoleDialog open={addRoleFlag} closeDialog={finishRoleAddition} />
      <EditRoleDialog
        open={editRoleFlag}
        role={role}
        closeDialog={finishRoleEdition}
      />
      <EditRouteDialog
        open={editRouteFlag}
        role={role}
        closeDialog={finishRouteEdition}
      />
      <EditPermissionDialog
        open={editPermissionFlag}
        role={role}
        closeDialog={finishPermissionEdition}
      />
      <DeleteRoleDialog
        open={deleteRoleFlag}
        role={role}
        closeDialog={finishRoleDeletion}
      />
    </AppLayout>
  );
}
