import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import _axios from "../../plugin/axios";

export default function EditProjectDialog({ open, project, closeDialog }) {
  // notistack变量区
  const { enqueueSnackbar } = useSnackbar();

  // state变量区
  const [name, setName] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [company, setCompany] = useState("");
  const [contact, setContact] = useState("");
  const [location, setLocation] = useState("");
  const [field, setField] = useState("");

  const infoInit = () => {
    setName(project.hasOwnProperty("name") ? project.name : "");
    setBaseUrl(project.hasOwnProperty("baseUrl") ? project.baseUrl : "");
    setCompany(project.hasOwnProperty("company") ? project.company : "");
    setContact(project.hasOwnProperty("contact") ? project.contact : "");
    setLocation(project.hasOwnProperty("location") ? project.location : "");
    setField(project.hasOwnProperty("field") ? project.field : "");
  };

  const submitEdition = () => {
    const putObj = {
      putProjectEntList: [
        { id: project.id, name, baseUrl, company, contact, location, field },
      ],
    };
    _axios.put("/rbac/project", putObj).then((resp) => {
      if (resp.data.code === "00") {
        closeDialog();
        enqueueSnackbar("修改项目信息成功！", { variant: "success" });
      } else {
        enqueueSnackbar("修改项目信息失败！", { variant: "error" });
      }
    });
  };

  useEffect(() => {
    infoInit();
  }, [project]);

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {
        closeDialog();
      }}
    >
      <DialogTitle>
        编辑项目信息
        <IconButton
          color="inherit"
          onClick={() => {
            closeDialog();
          }}
          sx={{ position: "absolute", right: 12, top: 12 }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"项目名称"}
          value={name}
          onInput={(event) => {
            setName(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"部署域名"}
          value={baseUrl}
          onInput={(event) => {
            setBaseUrl(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"所属公司"}
          value={company}
          onInput={(event) => {
            setCompany(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"公司地址"}
          value={location}
          onInput={(event) => {
            setLocation(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"公司联系方式"}
          value={contact}
          onInput={(event) => {
            setContact(event.target.value);
          }}
        />
        <TextField
          variant="standard"
          fullWidth
          margin="normal"
          label={"项目领域"}
          value={field}
          onInput={(event) => {
            setField(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => {
            submitEdition();
          }}
        >
          {"修改"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
