import axios from "axios";
import { store } from "../store";
import { removeToken } from "../store/slices/tokenSlice";
import { removeUserInfo } from "../store/slices/userInfoSlice";
import { setRoutesList } from "../store/slices/routesListSlice";
import { unauthRoutes } from "../routes";

const config = {
  baseURL: "https://admin.techrush.com.cn/project038/",
  // baseURL: "http://192.168.195.10:8080/project038/",
  timeout: 60 * 1000,
  withCredentials: true,
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    config.headers.Authorization = store.getState().token.value;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    if (response.data.code === "unauth-token") {
      store.dispatch(removeUserInfo);
      store.dispatch(removeToken);
      store.dispatch(setRoutesList(unauthRoutes));
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default _axios;
