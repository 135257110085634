import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import tokenReducer from "./slices/tokenSlice";
import routesListReducer from "./slices/routesListSlice";
import drawerStateReducer from "./slices/drawerStateSlice";
import userInfoReducer from "./slices/userInfoSlice";
import selectedProjectReducer from "./slices/selectedProjectSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["token", "userInfo"],
};

const reducers = combineReducers({
  token: tokenReducer,
  routesList: routesListReducer,
  drawerState: drawerStateReducer,
  userInfo: userInfoReducer,
  selectedProject: selectedProjectReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
